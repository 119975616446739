import React, { useState } from "react";
import { MdOutlineMail } from "react-icons/md";
import { LuPhoneCall } from "react-icons/lu";
import { SlLocationPin } from "react-icons/sl";
import page_6_1 from "../assets/page_6_11.webp";
import { Modal } from "react-bootstrap";
import axios from "axios";
import celebration from "../assets/successgif.gif";
import './modalContent.css';

export default function Page6() {

  const [formData, setFormData] = useState({
    yourname: "",
    emailAddress: "",
    telephone: "",
    message: ""
    
  });


  const [loading, setLoading] = useState(false);
  const [showModal,setShowModal]= useState(false);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

  };

  const handleSubmit = async (e) => {
    e.preventDefault();

      setLoading(true);
      try {
        const response = await axios.post(`https://www.andromeda-sim.com/emailapi/questionOrMessage_email.php`,
          formData
        );
        setShowModal(true);
        console.log("response.data", response.data);
        setFormData({
          yourname: "",
          emailAddress: "",
          telephone: "",
          message: ""
          
        });
    
      } catch (error) {
        console.error("Failed to send email:", error);
      } finally {
        setLoading(false);
      }
     
  };

  const handleContinue = () => {
    setShowModal(false);
    

  };


  return (
    <div className="container py-5 my-5">

      <div className="d-flex flex-column flex-lg-row">
        <div className="col-12 col-lg-6 px-0 pe-lg-5">
          <div className="px-2">
          <h1 className="fs-1 fw-bold page6phra_h1">Do you have a question?</h1>
          <p className="page6phra">
          We are friendly and available to respond to your messages. Reach out to us anytime and we’ll happily answer your questions.
          </p>
          </div>
          <div className="row">
            <div className="col-12  boarder">
              <div className="container">

                <div className="d-flex align-items-start bg-gray border shadow-sm p-3 rounded-3 my-4">
                  <MdOutlineMail className="me-2 fs-4 pt-1" />
                  <div>
                    <a href="mailto:hello@andromeda-sim.com" className="text-muted text-decoration-none">
                      <span className="text-black fw-medium d-block">
                        Email Us:
                      </span>
                      hello@andromeda-sim.com
                    </a>
                  </div>
                </div>

                <div className="border rounded-3  bg-white d-none d-md-block">
                <img src={page_6_1} className="img-fluid rounded-3 bg-white" alt="Signup image"  />
                </div>

                {/* <div className="d-flex align-items-start bg-gray border shadow-sm p-3 rounded-3 my-4">
                  <LuPhoneCall className="me-2 fs-4 pt-1" />
                  <div>
                    <a href="#" className="text-muted text-decoration-none">
                      <span className="text-black fw-medium d-block">
                      Hotline:
                      </span>
                      (239) 823 5070
                    </a>
                  </div>
                </div>

                <div className="d-flex align-items-start bg-gray border shadow-sm p-3 rounded-3 my-4">
                  <SlLocationPin className="me-2 fs-4 pt-1" />
                  <div>
                    <a href="#" className="text-muted text-decoration-none">
                      <span className="text-black fw-medium d-block">
                      Office Address:
                      </span>
                      Andromeda <br/>123 Aviation Street,London,SW1A 1AA
                    </a>
                  </div>
                </div> */}
                
              </div>
            </div>
            
          </div>
        </div>

        <div className="col-12 col-lg-6 px-3 px-lg-0">
          <form action="https://api.web3forms.com/submit" method="POST"  className="ps-0 ps-lg-3"> {/*onSubmit={handleSubmit}*/}

          <input type="hidden" name="access_key" value="24c4a5b9-e815-4e5b-b11e-7d7ca389010d"></input>{/*newly add line*/}

            <div className="mb-3 page6_form_labe">
              <label htmlFor="name" className="form-label">Your Name<span className="text-danger"> *</span></label>

              <input 
              type="text" 
              className="form-control border shadow-sm py-3 bg-light" 
              id="yourname"
              name="yourname"
              value={formData.yourname}
              onChange={handleChange}
              required
              />

            </div> 


            <div className="mb-3 page6_form_labe">
              <label htmlFor="email" className="form-label">Email Address<span className="text-danger"> *</span></label>

              <input type="email" 
              className="form-control border shadow-sm py-3 bg-light" 
              id="emailAddress"
              name="emailAddress"
             pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
              value={formData.emailAddress}
              onChange={handleChange}
              required
              />

            </div>


            <div className="mb-3 page6_form_labe">
              <label htmlFor="phone" className="form-label">Phone</label>

              <input type="tel" 
              className="form-control border shadow-sm py-3 bg-light" 
              id="telephone"
              name="telephone"
             
              value={formData.telephone}
              onChange={handleChange}
              required
             />
            </div>


            <div className="mb-3 page6_form_labe">
              <label htmlFor="message" className="form-label">Your Message/Question</label>

              <textarea 
              className="form-control bg-light" 
              id="message border shadow-sm" 
      
                name="message"
                value={formData.message}
                
                onChange={handleChange}
                rows="7">

              </textarea>
            </div>

            <button type="submit" className="btn-submitt w-100">Submit Message</button> {/* disabled={loading} >{loading ? "Sending..." : "Submit Message"}*/}
          </form>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <h2 className="text-center pt-4 box_content_heading">Message sent!</h2>
        <Modal.Body>
          <div className="box_content px-2">
          <div  className="d-flex col-12 align-items-center justify-content-center"> <img src={celebration} style={{width: "110px", height: "110px"}} alt="Celebration"/></div>
            <p className="phra">
            Thank you for reaching out to us! We understand your time is valuable, and we promise to get back to you within 3-4 business days.
            </p>
            
          </div>
        </Modal.Body>
        <div className="px-4 pt-3 pb-4">
          <button className="col-12 box_btn" onClick={handleContinue}>Done</button>
        </div>
      </Modal>

    </div>
  );
}
